import React from "react";
// import Header from "./header";
import HeaderNew from "../partials/HeaderNew";
import Footer from "../partials/Footer";
import "../styles/layout.css";

const Layout = ({ children, onHideNav, onShowNav, showNav, siteTitle }) => (
  <>
    {/* <Header
      siteTitle={siteTitle}
      onHideNav={onHideNav}
      onShowNav={onShowNav}
      showNav={showNav}
    /> */}
    <HeaderNew
      siteTitle={siteTitle}
      onHideNav={onHideNav}
      onShowNav={onShowNav}
      showNav={showNav}
    />
    <div className="flex-grow bg-teal-100">{children}</div>

    <Footer />
  </>
);

export default Layout;
