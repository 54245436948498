import React from "react";
import Image from "./Image.js";
import FeatherIcon from "feather-icons-react";

function Footer() {
  const DataSet = {
    columns: [
      {
        logourl: "s4.svg",
        subtext: "",
      },
      // {
      //   title: "Services",
      //   menu: [
      //     { text: "Brand Identity", url: "/" },
      //     { text: "Website Design", url: "/" },
      //     { text: "Design Thinking", url: "/" },
      //     { text: "Product Design", url: "/" },
      //     { text: "Design System", url: "/" },
      //     { text: "Frontend Development", url: "/" },
      //   ],
      // },
      {
        title: "Resources",
        menu: [
          { text: "Blog", url: "/", badge: "Under Construction", disable: true },
          { text: "Guideline", url: "/", badge: "Under Construction", disable: true },
          { text: "Freebies", url: "/", badge: "Under Construction", disable: true },
        ],
      },
      {
        title: "Tools & Products",
        menu: [
          { text: "FigPress", url: "/", badge: "soon", disable: true },
          { text: "FigStore", url: "/", badge: "soon", disable: true },
        ],
      },
    ],
    footnote: {
      social: [
        {
          name: "Linkedin",
          icon: "linkedin",
          url: "https://in.linkedin.com/in/pkshyamkrishnan",
        },
        {
          name: "Medium",
          icon: "globe",
          url: "https://medium.com/@shyam.ux",
        },
        {
          name: "Twitter",
          icon: "twitter",
          url: "https://twitter.com/shyam_ux",
        },
        {
          name: "Github",
          icon: "github",
          url: "https://github.com/pkshyamkrishnan",
        },
      ],
      note: "Powered By ☕. Made By Yours Truely",
    },
  };
  const Blocks = DataSet.columns.map((data, index) => {
    const colSize = 12 / DataSet.columns.length;
    const createCol = data.hasOwnProperty("logourl") ? (
      <div className={"sm:col-span-12 lg:col-span-" + colSize} key={index}>
        <div className="mb-2">
          {/* Logo */}
          <a href="/" className="inline-block" aria-label="ShyamUX">
            <Image
              src={data.logourl}
              alt="ShyamUX logo"
              style={{ height: "48px", width: "48px", objectFit: "cover" }}
            />
          </a>
          <p>uxaaar.com&trade; | {new Date().getFullYear()}</p>
        </div>
      </div>
    ) : (
      <div className={"sm:col-span-12 lg:col-span-" + colSize} key={index}>
        <h5 className="text-gray-800 font-bold mb-2">{data.title}</h5>
        <ul className="text-sm">
          {data.menu.map((item, number) => {
            const attr = item.disable
              ? {
                  role: "link",
                  "aria-disabled": item.disable,
                }
              : {
                  href: item.url,
                };
            return (
              <li className="mb-2" key={number}>
                <a
                  {...attr}
                  className="text-gray-600 capitalize hover:text-gray-900 transition duration-150 ease-in-out"
                >
                  {item.text}
                  {item.badge && (
                    <span className="ml-2 bg-blue-100 text-blue-800 text-xs font-semibold px-2.5 py-1 rounded dark:bg-blue-200 dark:text-blue-800">
                      {item.badge}
                    </span>
                  )}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
    return createCol;
  });

  const socialLink = DataSet.footnote.social.map((_data, _index) => {
    return (
      <li className="ml-4" key={_index}>
        <a
          href={_data.url}
          className="flex justify-center items-center text-gray-600 hover:text-gray-900 bg-white hover:bg-white-100 rounded-full p-2 transition duration-150 ease-in-out"
          aria-label={_data.name}
          title={_data.name}
        >
          <FeatherIcon icon={_data.icon}></FeatherIcon>
        </a>
      </li>
    );
  });
  return (
    <footer className="pb-16 md:pb-0">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        {/* Top area: Blocks */}
        <div className="grid sm:grid-cols-12 gap-8 py-8 md:py-12 border-t border-gray-200">
          {Blocks}
        </div>

        {/* Bottom area */}
        <div className="md:flex md:flex-row md:items-center md:justify-between flex flex-col items-center justify-between py-4 md:py-4 border-t border-gray-200">
          <ul className="flex mb-4 md:order-1 md:ml-4 md:mb-0">{socialLink}</ul>
          <div className="text-sm text-gray-600 mr-4">
            {DataSet.footnote.note}
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
