import { Link } from "gatsby";
import React, { useState, useEffect } from "react";
import Image from "./Image";
// import Icon from "../components/icon";
// import { cn } from "../lib/helpers";
import FeatherIcon from "feather-icons-react";
import * as styles from "../components/header.module.css";

function HeaderNew({ onHideNav, onShowNav, showNav, siteTitle }) {
  const [top, setTop] = useState(true);
  // detect whether user has scrolled the page down by 10px
  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true);
    };
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [top]);

  return (
    <header
      className={`fixed md:top-0 md:bottom-auto blur bottom-0 w-full z-30 bg-opacity-90 transition duration-300 ease-in-out ${
        !top || showNav ? "bg-white shadow-lg" : ""
      }`}
    >
      <div className="max-w-6xl mx-auto px-5 sm:px-6">
        <div className="py-2 flex items-center flex-col md:flex-row justify-between">
          {/* Site branding */}
          <div className="w-full md:w-auto flex items-center justify-between">
            {/* Logo */}
            <Link to="/" className="block" aria-label={siteTitle}>
              <Image
                className="logo mr-4"
                src="s4.svg"
                alt="shyam krishnan signage"
                style={{height: "64px", width: "64px", objectFit: "cover"}}
              />
            </Link>
            <button
              className={(styles.toggleNavButton, "block md:hidden lg:hidden")}
              onClick={showNav ? onHideNav : onShowNav}
            >
              {showNav ? <FeatherIcon icon="x" /> : <FeatherIcon icon="menu" />}
            </button>
          </div>

          {/* Site navigation */}

          <nav
            className={`w-full md:w-auto md:flex lg:flex flex-grow ${
              showNav ? "flex" : "hidden"
            }`}
          >
            <ul className="flex flex-col md:flex-row flex-grow md:justify-end items-center border-t-2 md:border-t-0">
              <li className="w-full md:w-auto border-b-2 md:border-b-0">
                <a
                  href="/about"
                  className="font-medium text-xl md:text-base text-gray-600 hover:text-gray-900 px-5 py-3 flex justify-center items-center transition duration-150 ease-in-out"
                >
                  About
                </a>
              </li>
              <li className="w-full md:w-auto border-b-2 md:border-b-0">
                <a
                  href="https://uxaaar.com/#Work"
                  className="font-medium text-xl md:text-base text-gray-600 hover:text-gray-900 px-5 py-3 flex justify-center items-center transition duration-150 ease-in-out"
                >
                  Recent Work
                </a>
              </li>
              {/* <li>
                <a
                  href="/about"
                  className="font-medium text-gray-600 hover:text-gray-900 px-5 py-3 flex items-center transition duration-150 ease-in-out"
                >
                  About Me{" "}
                </a>
              </li> */}
              <li className="w-full md:w-auto border-b-2 md:border-b-0">
                <a
                  href="https://uxaaar.com/#Contact"
                  className="font-medium text-xl md:text-base text-gray-600 hover:text-gray-900 px-5 py-3 flex justify-center items-center transition duration-150 ease-in-out"
                >
                  Get In Touch
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
}

export default HeaderNew;
